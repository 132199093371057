import styled from 'styled-components';

const Title = styled.h4`
    padding-top: 40px;
    font-size: 28px;
    line-height: 42px;
    a {
        :hover {
        }
        font-weight: 400;
        color: ${(props) => props.theme.promotedSection.colors.primary};
    }
`;

export default Title;
