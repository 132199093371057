import React, { useState, useRef, useMemo } from 'react';
import { Row, Col, Carousel } from 'antd';
import styled from 'styled-components';
import ArticleCard from 'components/ArticleCard';
import { SectionTitle } from 'components/Section';
import CarouselPagination from 'components/CarouselPagination';
import SeeMoreCard from 'components/SeeMoreCard';
import chunk from 'lib/chunkArray';

const GRID_COLS = 24;

const Section = styled.section`
    padding: 35px 0 10px 0;
`;

interface Props {
    title?: string | JSX.Element;
    url?: string;
    items: Article[];
    itemsPerRow?: number;
    rows?: number;
    seeMore?: boolean;
    noTeaser?: boolean;
    noImages?: boolean;
    autoplay?: boolean;
}

export const ArticleCarousel = ({
    title,
    url,
    items,
    itemsPerRow = 4,
    rows = 1,
    seeMore = true,
    noImages = false,
    noTeaser = false,
    autoplay = false
}: Props): React.ReactElement => {
    const [index, setIndex] = useState(0);
    const slider = useRef(null);

    if (seeMore && !url) {
        throw new Error(`Url is not provided for component ${title}`);
    }

    const goNext = () => {
        slider.current.next();
    };

    const goPrevious = () => {
        slider.current.prev();
    };

    const slides = useMemo(
        () => chunk(items || [], itemsPerRow * rows),
        [items, itemsPerRow, rows]
    );
    if (seeMore && slides[slides.length - 1]?.length === itemsPerRow) {
        slides[slides.length - 1].pop();
    }

    return (
        <Section key={`section-${title}`}>
            {title && <SectionTitle title={title} url={url} />}
            <Row key={`section-${title}-slider`}>
                <Col span={24}>
                    <Carousel
                        dots={false}
                        autoplaySpeed={7000}
                        autoplay={autoplay}
                        ref={slider}
                        afterChange={(index) => {
                            setIndex(index);
                        }}
                    >
                        {slides.map((slide, i) => {
                            return (
                                <div key={`carousel-${title}-slide-${i}`}>
                                    <Row gutter={[8, 16]}>
                                        {slide.map((item: Article) => {
                                            return (
                                                <ArticleCard
                                                    key={`carousel-${title}-item-${i}-${item.seoSlug}`}
                                                    $article={item}
                                                    noTeaser={noTeaser}
                                                    noImages={noImages}
                                                    size={{
                                                        lg: GRID_COLS / itemsPerRow,
                                                        md: 12,
                                                        xs: 24
                                                    }}
                                                />
                                            );
                                        })}
                                        {seeMore && i === slides.length - 1 && (
                                            <Col lg={GRID_COLS / itemsPerRow} md={12} xs={24}>
                                                <SeeMoreCard url={url} noImage={noImages} />
                                            </Col>
                                        )}
                                    </Row>
                                </div>
                            );
                        })}
                    </Carousel>
                </Col>
            </Row>
            <Row key={`section-${title}-pagination`}>
                <Col span={24}>
                    <CarouselPagination
                        goNext={goNext}
                        goPrevious={goPrevious}
                        currentSlide={index}
                        slides={slides}
                    />
                </Col>
            </Row>
        </Section>
    );
};

ArticleCarousel.whyDidYouRender = false;

export default ArticleCarousel;
