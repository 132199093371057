import styled from 'styled-components';

const Category = styled.h5`
    padding-top: 40px;
    font-size: 24px;
    line-height: 32px;
    color: white;
    a {
        :hover {
        }
        font-weight: 300;
        color: ${(props) => props.theme.promotedSection.colors.primary};
    }
`;

export default Category;
