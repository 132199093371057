import React from 'react';
import { Card } from 'antd';
import styled from 'styled-components';
import Link from 'next/link';
import Image from 'components/Image';
import Divider from 'components/Divider';
import Category from './Category';
import Title from './Title';
import parseCategoryUrl from 'lib/parseCategoryUrl';

const CardStyled = styled(Card)`
    background: inherit;
    .ant-card-body {
        padding: 0;
        background: inherit;
        text-align: center;
    }
    .ant-card-cover img {
        max-width: 100%;
        max-height: 100%;
    }
`;

const SponsoredArticleText = styled.small`
    color: ${(props) => props.theme.colors.blueGrey};
`;

interface Props {
    article: Article;
}

export const Item = ({
    article: { openingImage, openingImageInfo, title, seoSlug, category, sponsored = false }
}: Props): JSX.Element => (
    <CardStyled
        bordered={false}
        cover={
            openingImage && (
                <Link href={`/artykuly/${seoSlug}`}>
                    <Image
                        type="index"
                        width={569}
                        height={320}
                        alt={openingImageInfo.alt || title}
                        src={openingImage}
                    />
                </Link>
            )
        }
    >
        <Category>
            <Link href={parseCategoryUrl(category)}>{category.title}</Link>
        </Category>
        <Divider width="40%" centered />
        <Title>
            <Link href={`/artykuly/${seoSlug}`}>{title}</Link>
        </Title>
        {sponsored && <SponsoredArticleText>Artykuł sponsorowany</SponsoredArticleText>}
    </CardStyled>
);

export default Item;
