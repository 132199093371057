import React from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import Container from './Container';
import Card from './Card';
import AppSmallLogotype from 'components/Icons/AppSmallLogotype';
import Section from 'components/Section';
import ArticleCarousel from 'components/ArticleCarousel';
import shuffle from 'lib/shuffleArray';
import { merriweather } from 'styles/GlobalStyle';

const SubTitle = styled.span`
    font-family: ${merriweather.style.fontFamily};
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    text-align: center;
    line-height: 38px;
    padding-left: 15px;
    color: ${(props) => props.theme.colors.brownishGrey};
`;

const H1 = styled.h1`
    color: ${(props) => props.color || props.theme.colors.primary};
    font-family: ${merriweather.style.fontFamily};
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: 0.36px;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 0;
`;

const Icon = styled(AppSmallLogotype)`
    vertical-align: -0.25em;
    font-size: 34px;
`;

interface Props {
    campaign: Campaign;
}

const PromotedSection = ({ campaign }: Props): JSX.Element => {
    const [firstArticle, secondArticle, ...restArticles] = campaign.articles;

    const shuffledArticles = shuffle(restArticles);
    return (
        <section>
            <Section>
                <H1>
                    <Icon style={{ fontSize: 30 }} /> Poleca
                </H1>
                <SubTitle>{campaign.campaign.name}</SubTitle>
            </Section>
            <Section>
                <Col>
                    <Container>
                        <Col span={24}>
                            <Row style={{ padding: '10px 0 10px 10px' }} gutter={24}>
                                <Col md={12}>
                                    <Card article={firstArticle}></Card>
                                </Col>
                                <Col md={12}>
                                    <Card article={secondArticle}></Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={24} style={{ background: 'white', padding: '15px 15px 0 15px' }}>
                            <ArticleCarousel
                                items={shuffledArticles}
                                noTeaser
                                autoplay
                                seeMore={false}
                            />
                        </Col>
                    </Container>
                </Col>
            </Section>
        </section>
    );
};

export default PromotedSection;
